/*eslint no-undef: 0, no-unused-vars: 0*/

function parseParams(prefix, string) {
  if (string.indexOf(prefix) === 0) {
    string = string.substr(1);
  }
  if (!string) {
    return {};
  }
  return string.split('&').reduce(function (res, pair) {
    let indexOfEqual = pair.indexOf('=');
    if (indexOfEqual === -1) {
      res[pair] = undefined;
    } else {
      res[decodeURIComponent(pair.substr(0, indexOfEqual))] =
        decodeURIComponent(pair.substr(indexOfEqual + 1));
    }
    return res;
  }, {});
}

function getHashPart(uri) {
  let a = document.createElement('a');
  a.href = uri;
  return a.hash;
}

function getParams() {
  return parseParams('?', location.search);
}

function getScriptParams() {
  const scriptSrc = document.currentScript?.src || '';
  return parseParams('#', getHashPart(scriptSrc));
}

export default {
  getParams,
  getScriptParams,
  parseParams,
};
